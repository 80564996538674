<template>
  <div class="w-full h-full p-5">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="w-full h-full">
      <div class="flex">
        <h1 class="text-xl text-left font-extrabold mr-8">
          Dashboard
        </h1>
      </div>
      <card class="w-full p-5 mt-4">
        <profile-card app-page="Admin" />
        <div class="flex w-full">
          <div class="w-full flex flex-wrap">
            <div
              class="mb-7"
              style="width: 49%; margin-right: 1%; height: 595px;"
            >
              <card class="w-full h-full p-2">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-md">
                <div class="w-full mt-2 mb-5 flex">
                  <div class="flex flex-grow text-base ml-3 font-semibold">
                    Employee Count (
                    <div class="text-xs uppercase text-flame font-bold mt-1">
                      YTD
                    </div>
                    )
                  </div>
                </div>
                <div style="height: 100%" v-if="countsLoader">
                  <loader
                    size="xxs"
                    class="mt-10 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else class="flex flex-col">
                  <div
                    v-if="countLabel.length === 0"
                    class="flex flex-col text-center"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <div v-else>
                    <div class="mb-5 flex">
                      <span class="text-base flex flex-grow ml-3">
                        Current Count: {{ currentCount }}
                      </span>
                      <span class="text-base mr-2 font-semibold">
                        {{ Math.round(growthEmployee) }}%
                      </span>
                      <Icon
                        icon-name="chart-vector"
                        class="-mt-1 xsm"
                        style="color: #13B56A"
                      />
                    </div>
                    <LineChart
                      :data="employeeCount"
                      :options="countOptions"
                      :styles="lineStyles"
                      :value="false"
                    />
                    <!----<div class="mt-4 w-full text-center">
                      <span
                        @click="
                          $router
                            .push({ name: 'EmployeeDirectory' })
                            .catch(() => {})
                        "
                        class="text-blueCrayola text-base cursor-pointer"
                      >
                        View Details
                      </span>
                    </div>-->
                  </div>
                </div>
                </div>
              </card>
            </div>

            <div
              class="mb-7"
              style="width: 49%; margin-left: 1%; height: 595px;"
            >
              <card class="w-full h-full p-3">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-md">
                <div class="w-full mt-2 mb-5 flex">
                  <div class="flex flex-grow text-base ml-3 font-semibold">
                    Employee Cost (
                    <div class="text-xs uppercase text-flame font-bold mt-1">
                      YTD
                    </div>
                    )
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ costTab }}
                    </span>
                    <span class="">
                      <Menu left top="-20" margin="14" class="p-0 mt">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #F15A29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div
                            class="border w-full mb-2"
                            style="height: 0px;"
                          />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              costTab === 'Salary' ? 'bg-flame text-white' : ''
                            "
                            @click="setCostTab('Salary')"
                          >
                            <p class="pt-1">Salary</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              costTab === 'Pension' ? 'bg-flame text-white' : ''
                            "
                            @click="setCostTab('Pension')"
                          >
                            <p class="pt-1">Pension</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              costTab === 'PAYE' ? 'bg-flame text-white' : ''
                            "
                            @click="setCostTab('PAYE')"
                          >
                            <p class="pt-1">PAYE</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div style="height:100%" v-if="costLoader">
                  <loader
                    size="xxs"
                    class="mt-10 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else class="flex flex-col">
                  <div
                    v-if="costLabels.length === 0"
                    class="flex flex-col text-center"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <div v-else>
                    <div class="mb-5 flex">
                      <span class="text-base flex flex-grow ml-3">
                        Previous Month Cost:
                        {{ convertToCurrency(previousCosts) }}
                      </span>
                      <span class="text-base mr-2 font-semibold">
                        {{
                          costGrowth === null ? "0" : Math.round(costGrowth)
                        }}%
                      </span>
                      <Icon
                        icon-name="chart-vector"
                        class="-mt-1 xsm"
                        style="color: #13B56A"
                      />
                    </div>
                    <LineChart
                      :data="costsData"
                      :options="costsOptions"
                      :styles="lineStyles"
                      :value="false"
                    />
                    <!----<div class="mt-4 w-full text-center">
                      <span class="text-blueCrayola text-base">
                        View Details
                      </span>
                    </div> -->
                  </div>
                </div>
                </div>
              </card>
            </div>


            <div
              class="mb-9"
              style="width: 49%; margin-right: 1%; height: 595px;"
            >
              <card class="w-full h-full p-3">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-md">
                <div class="w-full mt-2 mb-5 flex">
                  <div class="flex flex-grow text-base ml-3 font-semibold">
                    Employee Movements (
                    <div class="text-xs uppercase text-flame font-bold mt-1">
                      YTD
                    </div>
                    )
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ movementTab }}
                    </span>
                    <span class="">
                      <Menu left top="-20" margin="14" class="p-0 mt">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #F15A29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div
                            class="border w-full mb-2"
                            style="height: 0px;"
                          />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              movementTab === 'Promotions'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setMovementTab('Promotions')"
                          >
                            <p class="pt-1">Promotions</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              movementTab === 'Transfers'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setMovementTab('Transfers')"
                          >
                            <p class="pt-1">Transfers</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              movementTab === 'Disengagements'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setMovementTab('Disengagements')"
                          >
                            <p class="pt-1">Disengagements</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div style="height: 100%" v-if="movementLoader">
                  <loader
                    size="xxs"
                    class="mt-10 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else class="flex flex-col">
                  <div
                    v-if="movementLabels.length === 0"
                    class="flex flex-col text-center"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <div v-else>
                    <div class="mb-5 flex">
                      <span class="text-base flex flex-grow ml-3">
                        Current Movement: {{ currentMovement }}
                      </span>
                      <span class="text-base mr-2 font-semibold">
                        {{
                          employeeMovementGrowth === null
                            ? "0"
                            : Math.round(employeeMovementGrowth)
                        }}%
                      </span>
                      <Icon
                        icon-name="chart-vector"
                        class="-mt-1 xsm"
                        style="color: #13B56A"
                      />
                    </div>
                    <LineChart
                      :data="movementData"
                      :options="movementOptions"
                      :styles="lineStyles"
                      :value="false"
                    />
                    <div
                      v-if="movementDetails === 'Disengaged Employees'"
                      class="mt-4 w-full text-center"
                    >
                      <span
                        @click="
                          $router
                            .push({
                              name: 'EmployeeDirectory',
                              query: { status: movementDetails }
                            })
                            .catch(() => {})
                        "
                        class="text-blueCrayola text-base cursor-pointer"
                      >
                        View Details
                      </span>
                    </div>
                  </div>
                </div>
                </div>
              </card>
            </div>


            <div
              class="mb-9"
              style="width: 49%; margin-left: 1%; height: 595px;"
            >
              <card class="w-full h-full p-3">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-md">
                <div class="text-base ml-3 mb-6 mt-2 font-semibold">
                  Pending Approvals
                </div>
                <div class="flex flex-col justify-center mt-8">
                  <div style="height: 100%" v-if="approvalsLoader">
                    <loader size="xxs" class="mt-72" :loader-image="false" />
                  </div>
                  <div
                    class="w-full flex justify-center"
                    v-if="approvalTotal > 0"
                  >
                    <DonutChart
                      :data="chartDataObject"
                      :options="chartOptions"
                      :styles="myStyles"
                      :value="approvalTotal"
                    />
                  </div>
                  <div
                    class="w-full mt-10 flex justify-center"
                    v-if="approvalTotal > 0"
                  >
                    <div
                      class="flex ml-4"
                      v-for="(legend, index) in chartLegend"
                      :key="index"
                    >
                      <div
                        class="rounded-full mr-2 mt-1"
                        :style="
                          `width: 12px; height: 12px; background-color: ${legend.color}`
                        "
                      />
                      <div class="text-sm text-romanSilver">
                        {{ legend.name }} : {{ legend.value }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="approvalTotal === 0"
                    class="flex flex-col text-center mb-3"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize"
                      style="width: 350px; height: 350px; margin: 0 auto"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                </div>
                </div>
              </card>
            </div>


            <div
              class="mb-7"
              style="width: 49%; margin-right: 1%; height: 595px;"
            >
              <card class="w-full h-full p-3">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-md">
                <div class="w-full mt-2 flex">
                  <div
                    class="flex flex-grow text-base ml-3 font-semibold"
                  >
                    <span class="flex">Birthdays</span>
                    <div class="text-sm uppercase text-flame font-bold flex flex-col justify-center mx-1">
                      ({{ birthdayData.length }})
                    </div>
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ birthdayTab }}
                    </span>
                    <span class="">
                      <Menu left top="-20" margin="14" class="p-0 mt">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #F15A29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div
                            class="border w-full mb-2"
                            style="height: 0px;"
                          />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              birthdayTab === 'Today'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setBirthdayTab('Today')"
                          >
                            <p class="pt-1">Today</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              birthdayTab === 'This Week'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setBirthdayTab('This Week')"
                          >
                            <p class="pt-1">This Week</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              birthdayTab === 'This Month'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setBirthdayTab('This Month')"
                          >
                            <p class="pt-1">This Month</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div style="height: 100%" v-if="birthdayLoader">
                  <loader
                    size="xxs"
                    class="mt-10 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="birthdayData.length === 0"
                    class="flex flex-col text-center mb-6"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <BirthdayCard :birthdays="birthdayData" v-else />
                </div>
                </div>
              </card>
            </div>


            <div
              class="mb-7"
              style="width: 49%; margin-left: 1%; height: 595px;"
            >
              <card class="w-full h-full p-3">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-md">
                <div class="w-full mt-2 flex">
                  <div
                    class="flex flex-grow text-base ml-3 font-semibold"
                  >
                    <span class="flex">Work Anniversaries</span>
                    <div class="text-sm uppercase text-flame font-bold flex flex-col justify-center mx-1">
                      ({{ anniversaryData.length }})
                    </div>
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ anniversaryTab }}
                    </span>
                    <span class="">
                      <Menu left top="-20" margin="14" class="p-0 mt">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #F15A29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div
                            class="border w-full mb-2"
                            style="height: 0px;"
                          />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              anniversaryTab === 'Today'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setAnniversaryTab('Today')"
                          >
                            <p class="pt-1">Today</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              anniversaryTab === 'This Week'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setAnniversaryTab('This Week')"
                          >
                            <p class="pt-1">This Week</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              anniversaryTab === 'This Month'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setAnniversaryTab('This Month')"
                          >
                            <p class="pt-1">This Month</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div style="height: 100%" v-if="anniversaryLoader">
                  <loader
                    size="xxs"
                    class="mt-10 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="anniversaryData.length === 0"
                    class="flex flex-col text-center mb-6"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <WorkAnniversary :anniversary="anniversaryData" v-else />
                </div>
                </div>
              </card>
            </div>


            <div class="mb-7 w-full">
              <card class="w-full h-full p-3">
                <div class="w-full flex flex-col my-2">
                  <div class="flex flex-grow">
                    <span class="text-base font-semibold">
                      Leave of Absence
                    </span>
                    <div class="text-sm uppercase text-flame font-bold flex flex-col justify-center mx-1">
                      ({{ absenceData.length }})
                    </div>
                  </div>
                  <CardFooter
                    @reload="reload()"
                    @actionModal="handleModal()"
                    @sortType="handleSort($event)"
                    @searchResult="handleSearch($event)"
                    class="mb-5 mt-2"
                  />
                  <div style="height: 100%" v-if="loadingTable">
                    <loader
                      size="xxs"
                      class="mt-10 mb-10"
                      :loader-image="false"
                    />
                  </div>
                  <div v-else>
                    <div
                      v-if="absenceData.length === 0"
                      class="flex flex-col text-center mb-3"
                    >
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width: 350px; height: 350px; margin: 0 auto"
                      />
                      <p class="text-xl font-bold">
                        No Data Available
                      </p>
                      <p class="text-xs text-romanSilver">
                        There is no data to show currently.
                      </p>
                    </div>
                    <div v-else>
                      <Table
                        :headers="absenceHeader"
                        :items="absenceData"
                        class="w-full my-2"
                        aria-label="absence table"
                        :loading="loadingTable"
                      >
                        <template v-slot:item="{ item }">
                          <div
                            v-if="item.fname"
                            style="width: 230px"
                            class="pt-3 pb-3"
                          >
                            <div class="flex">
                              <img
                                v-if="item.data.photo"
                                class="mr-2"
                                alt="photo"
                                style="width: 35px; height: 35px"
                                :src="item.data.photo"
                              />
                              <div
                                style="height: 35px; width: 35px; border-radius: 5px;"
                                class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                                v-else
                              >
                                {{
                                  $getInitials(
                                    `${item.data.fname} ${item.data.lname}`
                                  )
                                }}
                              </div>
                              <div class="w-auto flex flex-col">
                                <p
                                  class="text-sm font-semibold text-darkPurple"
                                >
                                  {{ `${item.data.fname} ${item.data.lname}` }}
                                </p>
                                <p class="text-xs font-black text-blueCrayola">
                                  {{ `${item.data.designation}` }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="item.delegate" style="width: 230px" class="pt-3 pb-3">
                            <div v-if="item.data.delegate" class="flex">
                              <div v-if="Object.keys(item.data.delegate).length" class="flex">
                                <img
                                  v-if="item.data.delegate.photo"
                                  class="mr-2"
                                  alt="photo"
                                  style="width: 35px; height: 35px"
                                  :src="item.data.delegate.photo"
                                />
                                <div
                                  style="height: 35px; width: 35px; border-radius: 5px;"
                                  class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                                  v-else
                                >
                                  {{
                                    $getInitials(
                                      `${item.data.delegate.fname} ${item.data.delegate.lname}`
                                    )
                                  }}
                                </div>
                                <div class="w-auto flex flex-col">
                                  <p
                                    class="text-sm font-semibold text-darkPurple"
                                  >
                                    {{ `${item.data.delegate.fname} ${item.data.delegate.lname}` }}
                                  </p>
                                  <p class="text-xs font-black text-blueCrayola">
                                    {{ `${item.data.delegate.designation}` }}
                                  </p>
                                </div>
                              </div>
                              <div v-else>Nil</div>
                            </div>
                            <div v-else>Nil</div>
                          </div>
                          <div v-else-if="item.totalDays">
                            <p>{{ item.data.totalDays }} days</p>
                          </div>
                          <span v-else-if="item.startDate" class="flex flex-col">
                            <span>
                            {{
                              `${$DATEFORMAT(
                                new Date(item.data.startDate),
                                "MMMM dd, yyyy"
                              )}`
                            }}
                          </span>
                          <span class="mx-2 text-sm text-blueCrayola font-bold">
                          to
                          </span>
                          <span>
                            {{
                              `${$DATEFORMAT(
                                new Date(item.data.endDate),
                                "MMMM dd, yyyy"
                              )}`
                            }}
                          </span>
                          </span>
                          <span v-else-if="item.returnDate" class="flex flex-col">
                            {{
                              `${$DATEFORMAT(
                                new Date(item.data.returnDate),
                                "MMMM dd, yyyy"
                              )}`
                            }}
                          </span>
                        </template>
                      </Table>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </card>
    </div>

    <ModalBar
      v-if="setModal"
      @submit="submitFilter"
      @close="resetModal()"
      submit="Apply"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <div class="w-full flex">
          <div class="text-darkPurple flex-grow">
            Filters
          </div>
          <div class="flex">
            <Icon
              class-name="text-darkPurple cursor-pointer mx-auto self-center"
              size="xs"
              icon-name="undo"
            />
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col">
        <SearchInput
          placeholder="First, Last Name or Email"
          class="search"
          style="width : 100%"
        />
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878E99;"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px;"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">
            Location(s)
          </p>
        </div>
        <div class="w-full border border-solid mt-2" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="locationArray"
            variant="w-full"
            class="mt-3 w-full"
            v-model="officeId"
          />
        </div>
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878E99;"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px;"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">
            Function(s)
          </p>
        </div>
        <div class="w-full border border-solid mt-2" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="functionArray"
            variant="w-full"
            class="mt-3 w-full"
            v-model="functionId"
          />
        </div>
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878E99;"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px;"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">
            Designation(s)
          </p>
        </div>
        <div class="w-full border border-solid mt-2" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="designationArray"
            variant="w-full"
            class="mt-3 w-full"
            v-model="designationId"
          />
        </div>
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878E99;"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px;"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">
            Leave Type(s)
          </p>
        </div>
        <div class="w-full border border-solid mt-2" style="height: 1px;" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="leaveArray"
            variant="w-full"
            class="mt-3 w-full"
            v-model="leaveId"
          />
        </div>
      </div>
    </ModalBar>
  </div>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import LineChart from "@/components/LineChart";
import DonutChart from "@/components/donutChart";
import { lazyLoadInternalComp } from "@/utilities/lazyLoadComponent";

export default {
  components: {
    Card: lazyLoadInternalComp('Card'),
    LineChart,
    SearchInput,
    CSelect,
    ModalBar: lazyLoadInternalComp('ModalBar'),
    BirthdayCard: lazyLoadInternalComp('BirthdayCard'),
    CardFooter: lazyLoadInternalComp('CardFooter'),
    WorkAnniversary: lazyLoadInternalComp('WorkAnniversary'),
    Table,
    DonutChart,
    Menu: lazyLoadInternalComp('Menu'),
    ProfileCard: lazyLoadInternalComp('profileCard'),
  },

  data() {
    return {
      costTab: "Salary",
      movementTab: "Promotions",
      birthdayTab: "This Month",
      anniversaryTab: "This Month",
      loadingTab: false,
      setModal: false,
      birthdayLoader: true,
      anniversaryLoader: true,
      designationArray: [],
      locationArray: [],
      functionArray: [],
      leaveArray: [],
      movementDetails: "",
      loadingTable: true,
      loading: true,
      movementLoader: true,
      countsLoader: true,
      costLoader: true,
      growthEmployee: "",
      costGrowth: "",
      employeeMovementGrowth: "",
      absenceHeader: [
        { title: "Employee", value: "fname" },
        { title: "Delegate", value: "delegate" },
        { title: "Leave Type", value: "leaveType" },
        { title: "Location", value: "location" },
        { title: "Function", value: "orgFunction" },
        { title: "Total Days", value: "totalDays" },
        { title: "Leave Duration", value: "startDate" },
        { title: "Return Date", value: "returnDate" }
      ],

      absenceData: [],
      birthdayData: [],
      anniversaryData: [],
      movementLabels: [],
      movementInfo: [],
      currentMovement: "",
      countLabel: [],
      countsInfo: [],
      currentCount: "",
      costLabels: [],
      costData: [],
      chartLabels: [],
      chartData: [],
      chartLegend: [],
      previousCosts: "",
      minCosts: "",
      maxCosts: "",
      maxMovement: "",
      minMovement: "",
      minCount: "",
      maxCount: "",
      approvalsLoader: true,
      approvalTotal: "",

      movementData: {
        labels: [],
        datasets: []
      },

      employeeCount: {
        labels: [],
        datasets: []
      },

      costsData: {
        labels: [],
        datasets: []
      },

      costsOptions: {},
      movementOptions: {},
      countOptions: {},

      chartDataObject: {},
      chartOptions: {
        legend: {
          display: false,
          position: "bottom"
        },
        responsive: true,
        maintainAspectRatio: false
      },

      lineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 100,
                stepValue: 50,
                max: 100
              },
              gridLines: {
                display: true,
                borderDash: [8, 4]
              }
            }
          ]
        }
      },

      lineStyles: {
        height: `390px`,
        width: `100%`,
        position: "relative"
      },

      myStyles: {
        height: `360px`,
        width: `360px`,
        position: "relative"
      },

      functionId: "",
      designationId: "",
      officeId: "",
      leaveId: "",
    };
  },

  methods: {
    handleModal() {
      this.setModal = true;
    },

    setCostTab(tab) {
      this.costTab = tab;
      this.costLoader = true;
      this.costLabels = [];
      this.costData = [];
      switch (tab) {
        case "Salary":
          this.getEmployeeCost("salary");
          break;
        case "Pension":
          this.getEmployeeCost("pension");
          break;
        case "PAYE":
          this.getEmployeeCost("paye");
          break;
        default:
          break;
      }
    },

    setMovementTab(tab) {
      this.movementTab = tab;
      this.movementLabels = [];
      this.movementInfo = [];
      this.movementLoader = true;
      switch (tab) {
        case "Promotions":
          this.movementDetails = "promotion";
          this.getEmployeeMovement("promotion");
          this.movementGrowth("promotion");
          break;
        case "Transfers":
          this.movementDetails = "transfer";
          this.getEmployeeMovement("transfer");
          this.movementGrowth("transfer");
          break;
        case "Disengagements":
          this.movementDetails = "Disengaged Employees";
          this.getEmployeeMovement("disengage");
          this.movementGrowth("disengage");
          break;
        default:
          break;
      }
    },

    setBirthdayTab(tab) {
      this.birthdayTab = tab;
      this.birthdayLoader = true;
      switch (tab) {
        case "Today":
          this.getBirthdays("day");
          break;
        case "This Week":
          this.getBirthdays("week");
          break;
        case "This Month":
          this.getBirthdays("month");
          break;
        default:
          break;
      }
    },

    setAnniversaryTab(tab) {
      this.anniversaryTab = tab;
      this.anniversaryLoader = true;
      switch (tab) {
        case "Today":
          this.getAnniversary("day");
          break;
        case "This Week":
          this.getAnniversary("week");
          break;
        case "This Month":
          this.getAnniversary("month");
          break;
        default:
          break;
      }
    },

    getLeaveAbsence() {
      this.$_getLeaveAbsence(this.$orgId).then(result => {
        this.loadingTable = false;
        const leaveAbsence = result.data.employees;
        this.absenceData = leaveAbsence.map(v => ({
          photo: v.employee.photo,
          fname: v.employee.fname,
          lname: v.employee.lname,
          designation: v.employee.designation,
          delegate: v.delegate,
          leaveType: v.leaveType,
          location: v.location,
          orgFunction: v.orgFunction,
          totalDays: v.totalDays,
          startDate: v.startDate,
          endDate: v.endDate,
          returnDate: v.returnDate
        }));
      });
    },

    getBirthdays(tab) {
      this.$_getEmployeeBirthdays(tab).then(result => {
        this.birthdayData = result.data.birthday;
        this.birthdayLoader = false;
      });
    },

    getAnniversary(tab) {
      this.$_getEmployeeWorkAnniversary(tab).then(result => {
        this.anniversaryData = result.data.employees;
        this.anniversaryLoader = false;
      });
    },

    getEmployeeMovement(tab) {
      this.$_getEmployeeMovements(tab).then(result => {
        let i;
        const currentMonth = this.$DATEFORMAT(new Date(), "MM") - 1;

        for (i = 0; i < result.data.data.length; i++) {
          this.movementLabels.push(result.data.data[i].month.toUpperCase());
          this.movementInfo.push(result.data.data[i].count);

          if (i === currentMonth) {
            this.currentMovement = result.data.data[i].count;
          }

          if (Math.ceil(Math.max(...this.movementInfo)) < 100) {
            this.maxMovement = 100;
          } else {
            this.maxMovement = Math.ceil(Math.max(...this.movementInfo));
          }

          this.minMovement = 0;
        }
        this.movementData = {
          labels: [...this.movementLabels],
          datasets: [
            {
              backgroundColor: "rgba(241, 90, 41, 0.08)",
              borderColor: "#321C3B",
              borderWidth: 1,
              data: [...this.movementInfo]
            }
          ]
        };

        this.movementOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: false,
                  steps: 1,
                  stepValue: 10,
                  max: this.maxMovement,
                  min: this.minMovement
                },
                gridLines: {
                  display: true,
                  borderDash: [8, 4]
                }
              }
            ]
          }
        };
        this.movementLoader = false;
      });
    },

    getEmployeeCount() {
      this.$_getEmployeeCounts().then(result => {
        let i;
        const currentMonth = this.$DATEFORMAT(new Date(), "MM") - 1;

        for (i = 0; i < result.data.data.length; i++) {
          this.countLabel.push(result.data.data[i].month.toUpperCase());
          this.countsInfo.push(result.data.data[i].count);

          if (i === currentMonth) {
            this.currentCount = result.data.data[i].count;
          }

          if (Math.ceil(Math.max(...this.countsInfo)) < 100) {
            this.maxCount = 100;
          } else {
            this.maxCount = Math.ceil(Math.max(...this.countsInfo));
          }

          this.minCount = 0;
        }

        this.employeeCount = {
          labels: [...this.countLabel],
          datasets: [
            {
              backgroundColor: "rgba(241, 90, 41, 0.08)",
              borderColor: "#321C3B",
              borderWidth: 1,
              data: [...this.countsInfo]
            }
          ]
        };
        this.countOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                display: true,
                stacked: false,
                ticks: {
                  beginAtZero: true,
                  steps: 1,
                  stepValue: 10,
                  max: this.maxCount,
                  min: this.minCount
                },
                gridLines: {
                  display: true,
                  borderDash: [8, 4]
                }
              }
            ]
          }
        };
        this.countsLoader = false;
      });
    },

    getEmployeeCost(tab) {
      this.$_getEmployeeCost(tab).then(result => {
        let i;

        for (i = 0; i < result.data.datas.length; i++) {
          this.costLabels.push(result.data.datas[i].month.toUpperCase());
          this.costData.push(result.data.datas[i].total);
        }

        if (Math.ceil(Math.max(...this.costData)) < 100) {
          this.maxCosts = 100;
        } else {
          this.maxCosts = Math.ceil(Math.max(...this.costData));
        }

        this.minCosts = 0;

        this.costsData = {
          labels: [...this.costLabels],
          datasets: [
            {
              backgroundColor: "rgba(241, 90, 41, 0.08)",
              borderColor: "#321C3B",
              borderWidth: 1,
              data: [...this.costData]
            }
          ]
        };

        this.costsOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                display: true,
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  steps: 1,
                  stepValue: 10,
                  max: this.maxCosts,
                  min: this.minCosts
                },
                gridLines: {
                  display: true,
                  borderDash: [8, 4]
                }
              }
            ]
          }
        };

        this.costLoader = false;
      });

      this.$_employeeCostGrowth(tab).then(result => {
        this.costGrowth = result.data.growth;
        this.previousCosts = result.data.prvousMonthCost;
      });
    },

    pendingApproval() {
      const idArr = [];
      const sumArr = [];
      const labelArr = [];
      let i;
      let sum = 0;
      let objData;

      this.$_getPendingApproval().then(result => {
        Object.keys(result.data).forEach(id => {
          const value = result.data[id];
          idArr.push(value.toString());
        });
        const arr = Object.keys(result.data).slice(1);
        this.chartData = idArr.slice(1);

        for (i = 0; i < arr.length; i++) {
          switch (arr[i]) {
            case "leaveRequest":
              objData = {
                color: "#878E99",
                name: "Leave Request",
                value: `${this.chartData[i]}`
              };
              labelArr.push(objData);
              break;

            case "advanceLoanRequests":
              objData = {
                color: "#E99323",
                name: "",
                value: `${this.chartData[i]}`
              };
              labelArr.push(objData);
              break;

            case "overtimeRequest":
              objData = {
                color: "#2176FF",
                name: "Overtime Request",
                value: `${this.chartData[i]}`
              };
              labelArr.push(objData);
              break;

            default:
              break;
          }
        }
        this.chartLabels = arr;
        this.chartLegend = labelArr;
        this.chartDataObject = {
          labels: [...this.chartLabels],
          datasets: [
            {
              backgroundColor: ["#878E99", "#E99323", "#2176FF"],
              offset: 30,
              data: [...this.chartData]
            }
          ]
        };

        this.approvalsLoader = false;
        this.chartData.map(item => sumArr.push(parseInt(item, 10)));

        for (i = 0; i < sumArr.length; i++) {
          sum += sumArr[i];
        }

        this.approvalTotal = sum;
      });
    },

    employeeGrowth() {
      this.$_countEmployeegrowth().then(result => {
        this.growthEmployee = result.data.growth;
      });
    },

    movementGrowth(tab) {
      this.$_countEmployeeMovement(tab).then(result => {
        this.employeeMovementGrowth = result.data.growth;
      });
    },

    getLocation() {
      this.$_getHumanarLocations().then(result => {
        this.locationArray = result.data.outlets.map(item => ({
          value: item.id,
          ...item
        }));
      });
    },
    getFunction() {
      this.$store.dispatch("org/getFunction").then(() => {
        this.functionArray = this.$store.state.org.getAllFunctions.map(
          item => ({
            value: item.id,
            ...item
          })
        );
      });
    },
    getDesignation(payload) {
      this.$_getDesignations(payload).then(response => {
        this.designationArray = response.data.designations.map(item => ({
          value: item.id,
          ...item
        }));
      });
    },
    getLeaveCategory() {
      this.$_getLeaveCategory(this.$orgId).then(result => {
        this.leaveArray = result.data.map(item => ({
          value: item.id,
          ...item
        }));
      });
    },

    submitFilter() {
      const functionId = this.functionId ? `functionId=${this.functionId}` : "";
      const location = this.officeId ? `&officeId=${this.officeId}` : "";
      const designation = this.designationId
        ? `&designationId=${this.designationId}`
        : "";
      const category = this.leaveId ? `&categoryId=${this.leaveId}` : "";

      this.loadingTable = true;
      this.setModal = false;

      this.$_getLeaveAbsence(
        `${this.$orgId}?${functionId}${designation}${location}${category}`
      ).then(result => {
        this.loadingTable = false;
        const leaveAbsence = result.data.employees;
        this.absenceData = leaveAbsence.map(v => ({
          photo: v.employee.photo,
          fname: v.employee.fname,
          lname: v.employee.lname,
          designation: v.employee.designation,
          leaveType: v.leaveType,
          location: v.location,
          orgFunction: v.orgFunction,
          totalDays: v.totalDays,
          startDate: v.startDate,
          endDate: v.endDate
        }));
        this.resetModal();
      });
    },

    resetModal() {
      this.functionId = "";
      this.officeId = "";
      this.designationId = "";
      this.leaveId = "";
      this.setModal = false;
    },

    processMethodGroups(methods, groupSize) {
      for (let i = 0; i < methods.length; i += groupSize) {

        this.$nextTick(() => {
          const groupPromises = methods.slice(i, i + groupSize).map(method => {
            return new Promise((resolve, reject) => {
              try {
                // Checked if method is actually a function before calling it
                if (typeof method === 'function') {
                  const result = method();  // Execute the function
                  if (result && typeof result.then === 'function') {
                    result.then(resolve).catch(reject);  // Handled promise
                  } else {
                    resolve(result);  // Resolved synchronous result
                  }
                } else {
                  throw new TypeError("Provided element is not a function");
                }
              } catch (error) {
                reject(error); 
              }
            });
          });

          Promise.all(groupPromises).catch(e => console.error(e));
        });
      }
    }

  },

  async mounted() {
    // Executed the first two critical function
    this.getEmployeeCount();
    this.getEmployeeCost("salary");
    this.loading = false;

    // Used Vue.nextTick to defer the execution of the next tasks
    this.$nextTick(() => {
      this.processMethodGroups([
        this.getEmployeeMovement.bind(this, "promotion"),
        this.movementGrowth.bind(this, "promotion"),
        this.employeeGrowth.bind(this),
        this.pendingApproval.bind(this),
        this.getBirthdays.bind(this, "month")
  ,     this.getAnniversary.bind(this, "month"),
        this.getLeaveAbsence.bind(this),
        this.getLocation.bind(this),
        this.getFunction.bind(this),
        this.getDesignation.bind(this, ""),
        this.getLeaveCategory.bind(this),
      ], 2);
    });
  },
};
</script>

<style scoped>
.iconSize {
  width: 20px;
  height: 20px;
}
.mt {
  margin-top: 0.37rem;
}
</style>
